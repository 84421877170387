import React from 'react';
import { Box, Stack, Container, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import LoginForm from '../../sections/auth/login/LoginForm';
import Page from '../../components/page/Page';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

export default function Login() {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate("/");
  }

  return (
    <Page title="Login"
      maxWidth="2xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: 'url(https://cdn.shopify.com/s/files/1/0402/9851/2547/files/Tradein_LogInPopup.png?v=1714479621)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <RootStyle>
        <Container maxWidth="md">
          <ContentStyle>
            <Stack direction="column" alignItems="center" spacing={2}>
              <Box mt={2}>
                <Logo />
              </Box>

              <Box sx={{ position: "absolute", top: 20, right: 5 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleBackButton}
                  sx={{
                    borderRadius: '99px',
                    color: 'white',
                    borderColor: 'white',
                    fontSize: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '1rem'
                    }
                  }}
                >
                  Back to Calculator
                </Button>
              </Box>

              <Box sx={{
                width: '100%',
                maxWidth: {
                  xs: '95%',
                  sm: '80%',
                  md: '500px'
                },
                background: 'white',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                borderRadius: '10px',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    color: "#012355",
                    fontSize: {
                      xs: '28px',
                      sm: '34px',
                      md: '40px'
                    },
                    fontWeight: "900",
                    wordWrap: "break-word"
                  }}>
                  Login
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                  Enter your details below.
                </Typography>
                <LoginForm />
              </Box>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
