import { createContext, useEffect, useReducer } from 'react';
// auth
import {
    createUserWithEmailAndPassword, onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword, signOut,
} from 'firebase/auth';
import { useSnackbar } from "notistack";


const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    metadata: null
}

const Types = {
    Initial: 'INITIALISE',
};

const reducer = (state, action) => {
    if (action.type === Types.Initial) {
        const { isAuthenticated, user, metadata } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            metadata
        }
    }

    return state;
}

const AuthContext = createContext(null);

function AuthProvider ({ children, auth }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user != null) {
                // console.log(user)
                const result = await user.getIdTokenResult();
                const token = result.token;
                // console.log(token)
                sessionStorage.setItem('token', token);
                const metadata = { ...result.claims };

                dispatch({
                    type: Types.Initial,
                    payload: { isAuthenticated: true, user, metadata }
                });
            } else {
                dispatch({
                    type: Types.Initial,
                    payload: { isAuthenticated: false, user: null, metadata: null }
                });
                sessionStorage.clear();
            }
        });

        return () => unsubscribe();
    }, [auth, dispatch]);

    const login = async (email, password) =>
        await signInWithEmailAndPassword(auth, email, password);

    const register = async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password);
    }

    const logout = async () => { await signOut(auth); }

    const resetPassword = async (email) => {
        await sendPasswordResetEmail(auth, email)
            .then(() => enqueueSnackbar("Password reset email sent."))
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'firebase',
                user: {
                    id: state?.user?.uid,
                    email: state?.user?.email,
                    photoURL: state?.user?.photoURL,
                    displayName: state?.user?.displayName,
                    metadata: state?.metadata,
                    phoneNumber: state?.user?.phoneNumber || ''
                },
                login,
                register,
                logout,
                resetPassword
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider, Types };
