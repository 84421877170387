// @mui
import { Box } from '@mui/material'
// helmet
import { Helmet, HelmetProvider } from 'react-helmet-async'

// ----------------------------------------------------------------------

const Page = ({ children, title = '', meta, ...other }) => (
    <HelmetProvider>
        <Helmet>
            <title>{`${title} | Rentoza`}</title>
            {meta}
        </Helmet>

        <Box {...other}>
            {children}
        </Box>
    </HelmetProvider>
)

export default Page
