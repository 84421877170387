import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth/Login';

export default function AuthGuard({ children, loginPage = <Login /> }) {
    const { isAuthenticated, isInitialized } = useAuth()
    const { pathname } = useLocation()
    const [requestedLocation, setRequestedLocation] = useState(null)
   
    const LoadingScreen = () =>{
        return(
            <>Loading</>
        )
    }
      if (!isInitialized) {
        return <LoadingScreen />
      }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <>{loginPage}</>
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}
