import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RolebasedGuard";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const LoadingScreen = () => {
  return <>Loading</>;
};

export default function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="admin-panel"
            element={
              <AuthGuard>
                <RoleBasedGuard hasContent roles={['admin', 'super_admin']}>
                  <Admin />
                </RoleBasedGuard>
              </AuthGuard>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

const Home = Loadable(lazy(() => import("../pages/home/Home")));
const Admin = Loadable(lazy(() => import("../pages/admin/Admin")));
