import React from 'react';
import Router from './routes/index';

const App = () => {

  return (
      <Router />
  );
};

export default App;
