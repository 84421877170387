const initialState = {
    product_types: [],
  };
  
  export const productTypeSlice = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PRODUCT_TYPES':
        return {
          ...state,
          product_types: action.payload,
        };
      case 'ADD_PRODUCT':
        return {
          ...state,
          product_types: [...state.product_types, action.payload],
        };
      case 'REMOVE_PRODUCT':
        return {
          ...state,
          product_types: state.product_types.filter(product => product.name !== action.payload),
        };
      default:
        return state;
    }
  };
  