import React from 'react';
import { Box } from '@mui/material';

function Logo() {
  return (
    <Box sx={{  padding:"5px" , display: 'flex',  justifyContent: 'center',}}>
      <img src="https://cdn.shopify.com/s/files/1/0402/9851/2547/files/RentozaForBusiness2024-01_3.png?v=1714465555" alt="Logo - For Business" />
    </Box>
  );
}

export default Logo;
