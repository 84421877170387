import React, { useState, useEffect } from 'react';
import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { MotionContainer , varBounce  } from '../components/animate';
import { ForbiddenIllustration } from '../assets';

export default function RoleBasedGuard ({ hasContent, roles, children }) {
    // Logic here to get current user role
    const { user, logout } = useAuth();
    const userRoles = Object.keys(user?.metadata.scopes || {}).map((scope) => scope.split(':')[0]);
    const [countdown, setCountdown] = useState(3); // Initial countdown value
    const [logoutInitiated, setLogoutInitiated] = useState(false);

    const checkPermission = (roles, userRoles) => {
        return roles?.some((role) => userRoles.includes(role));
    };
  
    const hasPermission = checkPermission(roles, userRoles);

    useEffect(() => {
        let timer;
        if (!hasPermission && !logoutInitiated) {
            // Start countdown if user doesn't have permission and logout isn't initiated yet
            timer = setInterval(() => {
                setCountdown((prevCount) => prevCount - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [hasPermission, logoutInitiated]);

    useEffect(() => {
        // Initiate logout when countdown reaches 0
        if (countdown === 0 && !logoutInitiated) {
            logout();
            setLogoutInitiated(true);
        }
    }, [countdown, logout, logoutInitiated]);

    if (typeof roles !== 'undefined' && !hasPermission) {
        return hasContent ? (
            <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                        Permission Denied
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        You do not have permission to access this page
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography variant="h6" paragraph>
                        Logging out in {countdown} seconds...
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                </m.div>
            </Container>
        ) : null;
    }

    return <>{children}</>;
}
