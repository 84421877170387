import * as Yup from 'yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Alert, IconButton, InputAdornment, useTheme, useMediaQuery } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth, useIsMountedRef } from '../../../hooks'
import Iconify from '../../../components/iconify/Iconify'
import FormProvider from '../../../components/hook-form/FormProvider'
import RHFTextField from '../../../components/hook-form/RHFTextField'
import { RHFCheckbox } from '../../../components/hook-form/RHFCheckbox'

export default function LoginForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { login } = useAuth()
  const { isMountedRef } = useIsMountedRef()
  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    remember: Yup.boolean().optional(),
    afterSubmit: Yup.string().optional(),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password)
    } catch (error) {
      console.error(error)
      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...(error), message: (error).message })
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!(errors.afterSubmit == null) && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField name="email" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => { setShowPassword(!showPassword) }} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        {/* <Link component={RouterLink} variant="subtitle2" to={'reset-password'}>
          Forgot password?
        </Link> */}
      </Stack>
      <LoadingButton
        fullWidth
        size={isMobile ? 'medium' : 'large'}
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{borderRadius:'99px'}}
      >
        Login
      </LoadingButton>
    </FormProvider>
  )
}
