import React from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./context/FirebaseContext";
import { getAuth } from "firebase/auth";
import "./index.css";
import { FIREBASE_API, TERNANT_ID } from "./config";
import { store, persistor } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const firebaseApp = initializeApp(FIREBASE_API);
export const AUTH = getAuth(firebaseApp);
AUTH.tenantId = TERNANT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <AuthProvider auth={AUTH}>
          <HelmetProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </ReduxProvider>
          </HelmetProvider>
        </AuthProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
